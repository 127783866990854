import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["aria-label", "href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATypographyTitle = _resolveComponent("ATypographyTitle")!
  const _component_AAvatar = _resolveComponent("AAvatar")!
  const _component_ACardMeta = _resolveComponent("ACardMeta")!
  const _component_MailOutlined = _resolveComponent("MailOutlined")!
  const _component_ACard = _resolveComponent("ACard")!
  const _component_AListItem = _resolveComponent("AListItem")!
  const _component_AList = _resolveComponent("AList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ATypographyTitle, { level: 1 }, {
      default: _withCtx(() => [
        _createTextVNode("Contributors")
      ]),
      _: 1
    }),
    _createVNode(_component_AList, {
      itemLayout: "horizontal",
      dataSource: _ctx.contributors,
      grid: _ctx.defaultGridParams
    }, {
      renderItem: _withCtx(({ item: contributor }) => [
        _createVNode(_component_AListItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_ACard, { hoverable: "" }, {
              actions: _withCtx(() => [
                _createElementVNode("a", {
                  "aria-label": contributor.email,
                  href: `mailto:${contributor.email}`
                }, [
                  _createVNode(_component_MailOutlined)
                ], 8, _hoisted_1)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_ACardMeta, {
                  title: contributor.name
                }, {
                  description: _withCtx(() => [
                    _createTextVNode(_toDisplayString(contributor.institute || "N/A"), 1)
                  ]),
                  avatar: _withCtx(() => [
                    _createVNode(_component_AAvatar, { src: _ctx.avatarGenerator }, null, 8, ["src"])
                  ]),
                  _: 2
                }, 1032, ["title"])
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["dataSource", "grid"])
  ], 64))
}