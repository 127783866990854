import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATypographyTitle = _resolveComponent("ATypographyTitle")!
  const _component_ATypographyParagraph = _resolveComponent("ATypographyParagraph")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ATypographyTitle, { level: 1 }, {
      default: _withCtx(() => [
        _createTextVNode("404 Not Found")
      ]),
      _: 1
    }),
    _createVNode(_component_ATypographyParagraph, null, {
      default: _withCtx(() => [
        _createTextVNode("You are lost. Click on the logo to go back to home.")
      ]),
      _: 1
    })
  ], 64))
}