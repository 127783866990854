
import { defineComponent } from "vue";
import { contributors } from "@/data";
import { MailOutlined } from "@ant-design/icons-vue";
import { defaultGridParams, avatarGenerator } from "@/consts";

export default defineComponent({
  components: {
    MailOutlined,
  },
  setup() {
    return { defaultGridParams, avatarGenerator };
  },
  data() {
    return {
      contributors,
    };
  },
});
