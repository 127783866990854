import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { style: {"height":"3rem","width":"250px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATypographyTitle = _resolveComponent("ATypographyTitle")!
  const _component_ACardMeta = _resolveComponent("ACardMeta")!
  const _component_ACard = _resolveComponent("ACard")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_AListItem = _resolveComponent("AListItem")!
  const _component_AList = _resolveComponent("AList")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ATypographyTitle, { level: 1 }, {
      default: _withCtx(() => [
        _createTextVNode("Topics")
      ]),
      _: 1
    }),
    _createVNode(_component_AList, {
      itemLayout: "horizontal",
      dataSource: _ctx.topics,
      grid: { gutter: 16 }
    }, {
      renderItem: _withCtx(({ item: topic }) => [
        _createVNode(_component_AListItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_RouterLink, {
              to: `/topic/${topic.index}`,
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ACard, {
                  class: "topic-card",
                  hoverable: ""
                }, {
                  cover: _withCtx(() => [
                    _createElementVNode("img", {
                      src: topic.snapshot,
                      class: "topic-image"
                    }, null, 8, _hoisted_1)
                  ]),
                  default: _withCtx(() => [
                    _createVNode(_component_ACardMeta, {
                      title: `Topic ${topic.index}`
                    }, {
                      description: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString(topic.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["title"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["dataSource"])
  ], 64))
}