
import { defineComponent } from "vue";
import { topics } from "@/data";
import { defaultGridParams } from "@/consts";

export default defineComponent({
  setup() {
    return {
      defaultGridParams,
    };
  },
  data() {
    return { topics };
  },
});
