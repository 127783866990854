
import { Layout } from "ant-design-vue";
import { defineComponent } from "vue";

export default defineComponent({
  computed: {
    layout() {
      return (this.$route.meta.layout || Layout.Sider);
    }
  },
});
