import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ATypographyTitle = _resolveComponent("ATypographyTitle")!
  const _component_ATypographyParagraph = _resolveComponent("ATypographyParagraph")!
  const _component_LinkOutlined = _resolveComponent("LinkOutlined")!
  const _component_ASpace = _resolveComponent("ASpace")!
  const _component_ATypographyLink = _resolveComponent("ATypographyLink")!
  const _component_ATypographyText = _resolveComponent("ATypographyText")!
  const _component_AListItem = _resolveComponent("AListItem")!
  const _component_AList = _resolveComponent("AList")!
  const _component_ACard = _resolveComponent("ACard")!
  const _component_AImage = _resolveComponent("AImage")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ATypographyTitle, { level: 1 }, {
      default: _withCtx(() => [
        _createTextVNode("Others")
      ]),
      _: 1
    }),
    _createVNode(_component_ATypographyParagraph, null, {
      default: _withCtx(() => [
        _createTextVNode("Additional information about GDP and us.")
      ]),
      _: 1
    }),
    _createVNode(_component_AList, {
      itemLayout: "horizontal",
      dataSource: _ctx.materials,
      grid: {gutter: 16, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 3, xxxl: 3}
    }, {
      renderItem: _withCtx(({ item: material }) => [
        _createVNode(_component_AListItem, null, {
          default: _withCtx(() => [
            _createVNode(_component_ACard, {
              title: material.name
            }, {
              default: _withCtx(() => [
                _createVNode(_component_AList, {
                  itemLayout: "horizontal",
                  dataSource: material.data
                }, {
                  renderItem: _withCtx(({ item: content }) => [
                    _createVNode(_component_AListItem, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_ATypographyText, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_ATypographyLink, {
                              href: content.uri,
                              target: "_blank"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ASpace, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_LinkOutlined),
                                    _createElementVNode("span", null, _toDisplayString(content.name), 1)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1032, ["href"]),
                            (content.author)
                              ? (_openBlock(), _createBlock(_component_ATypographyText, { key: 0 }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(" by " + _toDisplayString(content.author), 1)
                                  ]),
                                  _: 2
                                }, 1024))
                              : _createCommentVNode("", true)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _: 2
                }, 1032, ["dataSource"])
              ]),
              _: 2
            }, 1032, ["title"])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["dataSource"]),
    _createVNode(_component_ATypographyTitle, { level: 2 }, {
      default: _withCtx(() => [
        _createTextVNode("GDP Group Photos")
      ]),
      _: 1
    }),
    _createVNode(_component_AList, {
      itemLayout: "horizontal",
      dataSource: _ctx.otherImages,
      grid: _ctx.defaultGridParams
    }, {
      renderItem: _withCtx(({ item: image }) => [
        _createVNode(_component_AListItem, { style: {
        textAlign: 'center',
      } }, {
          default: _withCtx(() => [
            _createVNode(_component_AImage, {
              src: image.image
            }, null, 8, ["src"]),
            _createVNode(_component_ATypographyText, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(image.title), 1)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["dataSource", "grid"])
  ], 64))
}