
import { defineComponent } from "vue";
import { otherEssays, otherLinks, otherImages } from "@/data";
import { LinkOutlined } from "@ant-design/icons-vue";
import { defaultGridParams } from "@/consts";

export default defineComponent({
  components: {
    LinkOutlined,
  },
  setup() {
    return {
      defaultGridParams,
    };
  },
  data() {
    return {
      materials: [{
        name: "Essays",
        data: otherEssays,
      }, {
        name: "Links",
        data: otherLinks,
      }],
      otherImages,
    };
  },
});
